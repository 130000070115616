import React, { useState } from "react";
import {
  UilCopy,
  UilEye,
  UilEyeSlash,
  UilEdit,
} from "@iconscout/react-unicons";
import toast from "react-hot-toast";
import { Api } from "../models/ApiKeys";
import { ApiService } from "../services/apiService";
import { useTranslation } from "react-i18next";

type UserApiKeysProps = {
  apis: Api[];
};

const UserApiKeys: React.FC<UserApiKeysProps> = ({ apis }) => {
  const {t} = useTranslation();
  const [showSecret, setShowSecret] = useState<boolean[]>(
    apis.map(() => false)
  );
  const [editedIps, setEditedIps] = useState<string[]>(
    apis.map((api) => api.ipAddress)
  );
  const [isEditing, setIsEditing] = useState<boolean[]>(apis.map(() => false));
  const [savingState, setSavingState] = useState<boolean[]>(
    apis.map(() => false)
  );

  const toggleSecretVisibility = (index: number) => {
    setShowSecret((prev) => prev.map((v, i) => (i === index ? !v : v)));
  };

  const handleCopy = (key: string) => {
    navigator.clipboard
      .writeText(key)
      .then(() => {
        showToast("Copied to clipboard!", true);
      })
      .catch(() => {
        showToast("Failed to copy! Please try again.", false);
      });
  };

  const handleIpChange = (index: number, newIp: string) => {
    setEditedIps((prev) => prev.map((ip, i) => (i === index ? newIp : ip)));
  };

  const handleEditToggle = (index: number) => {
    setIsEditing((prev) =>
      prev.map((editing, i) => (i === index ? !editing : editing))
    );
  };

  const handleEditIP = async (index: number) => {
    const updatedApi = { ...apis[index], ipAddress: editedIps[index] };

    try {
      setSavingState((prev) =>
        prev.map((saving, i) => (i === index ? true : saving))
      );

      const data = {
        userWhatsAppNumberId: updatedApi.userWhatsAppNumber.id,
        ipAddress: updatedApi.ipAddress,
      };
      const response = await ApiService.userEditIp(data);

      if (response.status === -1) {
        showToast(
          response.message || "Something went wrong. Please try again later",
          false
        );
        return;
      }

      setIsEditing((prev) =>
        prev.map((editing, i) => (i === index ? false : editing))
      );
      showToast("IP address updated successfully!", true);
    } catch (error) {
      showToast(
        "An error occurred while saving. Please try again later.",
        false
      );
    } finally {
      setSavingState((prev) =>
        prev.map((saving, i) => (i === index ? false : saving))
      );
    }
  };

  const showToast = (message: string, isSuccess: boolean) => {
    toast[isSuccess ? "success" : "error"](message, {
      style: {
        border: `1px solid ${isSuccess ? "#28a745" : "#dc3545"}`,
        background: isSuccess ? "#d4edda" : "#f8d7da",
        color: isSuccess ? "#155724" : "#721c24",
      },
      iconTheme: {
        primary: isSuccess ? "#28a745" : "#dc3545",
        secondary: isSuccess ? "#d4edda" : "#f8d7da",
      },
    });
  };

  return (
    <div>
      {apis.map((api, index) => (
        <div key={index} className="card">
          <div className="card-header">
            <h5 className="card-title">
              {t("userApiKeys1")}:{" "}
              <span className="text-secondary">
                {api.userWhatsAppNumber.phoneNumber}
              </span>
            </h5>
          </div>
          <div className="w-100 card-body">
            <div className="mb-3">
              <label className="fw-bold fs-14 text-black mb-1" htmlFor="ip">
               {t("userApiKeys2")}{" "}
                <span
                  className={`badge ${
                    api.isIpWhitelisted
                      ? "bg-soft-success text-success"
                      : "bg-soft-danger text-danger"
                  } ms-2`}
                >
                  {api.isIpWhitelisted ? "IP whitelisted" : "IP blacklisted"}
                </span>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="IP Address"
                  id="ip"
                  value={editedIps[index]}
                  onChange={(e) => handleIpChange(index, e.target.value)}
                  readOnly={!isEditing[index]} // Toggle read-only based on editing state
                />
                <button
                  className="input-group-text text-primary"
                  onClick={() => handleEditToggle(index)} // Toggle editing
                >
                  <UilEdit />
                </button>
                <button
                  className="input-group-text text-primary"
                  onClick={() => handleCopy(api.public_key)}
                >
                  <UilCopy />
                </button>
              </div>
              {isEditing[index] && (
                <button
                  className="btn btn-primary mt-2"
                  onClick={() => handleEditIP(index)}
                  disabled={savingState[index]} // Disable while saving
                >
                  {savingState[index] ? "Saving..." : "Save"}
                </button>
              )}
            </div>
            <div className="mb-3">
              <label className="fw-bold fs-14 text-black mb-1" htmlFor="pk">
                {t("userApiKeys3")}
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Public Key"
                  id="pk"
                  readOnly
                  value={api.public_key}
                />
                <button
                  className="input-group-text text-primary"
                  onClick={() => handleCopy(api.public_key)}
                >
                  <UilCopy />
                </button>
              </div>
            </div>
            <div className="mb-3">
              <label className="fw-bold fs-14 text-black mb-1" htmlFor="sk">
                {t("userApiKeys4")}
              </label>
              <div className="input-group">
                <input
                  type={showSecret[index] ? "text" : "password"}
                  className="form-control"
                  placeholder="Secret Key"
                  id="sk"
                  readOnly
                  value={api.secret_key}
                />
                <button
                  className="input-group-text text-primary"
                  onClick={() => toggleSecretVisibility(index)}
                >
                  {showSecret[index] ? <UilEyeSlash /> : <UilEye />}
                </button>
                <button
                  className="input-group-text text-primary"
                  onClick={() => handleCopy(api.secret_key)}
                >
                  <UilCopy />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserApiKeys;
