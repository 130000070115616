import React, { useEffect, useState, useCallback } from "react";
import { UilEllipsisV, UilSave, UilFileAlt } from "@iconscout/react-unicons";
import Creatable from "react-select/creatable";
import toast from "react-hot-toast";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { useDispatch, useSelector } from "react-redux";
import { getAllContacts } from "../../redux/slices/contactSlice";
import { sendMessage } from "../../redux/slices/smsSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { MultiValue, ActionMeta } from "react-select";
import { smsService } from "../../services/smsService";
import { SmsType } from "../../models/SMS";
import { useTranslation } from "react-i18next";

type Props = {};
export interface OptionType {
  value: string;
  label: string;
}

export const customStyles = {};

const SingleSMS = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { contacts } = useSelector((state: RootState) => state.contacts);
  const [submitting, setSubmitting] = useState(false);
  const [title, setTitle] = useState("");
  const [to, setTo] = useState<OptionType[]>([]);
  const [selectedType, setSelectedType] = useState<SmsType | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [message, setMessage] = useState("");
  const [smsType, setSmsType] = useState<SmsType[]>([]);
  const dispatch: AppDispatch = useDispatch();

  const handleSend = useCallback(async () => {
    setSubmitting(true);
    const contactLookup = contacts.reduce((acc, contact) => {
      if (contact.phone) acc[contact.phone] = contact;
      return acc;
    }, {} as Record<string, (typeof contacts)[0]>);

    const formattedContacts = to.map((option) => ({
      firstname: contactLookup[option.value]?.firstname || "",
      lastname: contactLookup[option.value]?.lastname || "",
      email: contactLookup[option.value]?.email || "",
      phone: `+${option.value}`,
    }));

    const data = {
      sender: title,
      smsContent: message,
      contactsJson: formattedContacts,
      contacts: [],
      messageType: selectedType as SmsType,
    };

    try {
      const res: any = await dispatch(sendMessage({ data })).unwrap();
      if (res.status === -1) {
        toast.error(res.message);
        return;
      }
      toast.success("Request initiated!");
      setTitle("");
      setTo([]);
      setMessage("");
      setSelectedType(null);
    } catch (error: any) {
      toast.error("Failed to send SMS: " + error.message);
    } finally {
      setSubmitting(false);
    }
  }, [title, to, message, contacts, dispatch, selectedType]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value.replace(/\/../g, ""));
  };

  const handleToChange = (
    newValue: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    setTo(newValue as OptionType[]);
  };

  const handleSmsTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = smsType.find(
      (type) => type.name === event.target.value
    );
    setSelectedType(selectedType || null);
  };

  const handleBlur = () => {
    if (inputValue.trim()) {
      const newOption: OptionType = {
        label: inputValue,
        value: inputValue,
      };
      setTo((prev) => [...prev, newOption]);
      setInputValue("");
    }
  };

  const fetchSmsTypes = async () => {
    try {
      const res = await smsService.getSmsType();
      setSmsType(res.data);
    } catch (error) {
      setSmsType([]);
    }
  };

  useEffect(() => {
    fetchSmsTypes();
  }, []);

  useEffect(() => {
    dispatch(getAllContacts());
  }, [dispatch]);

  const transformedContacts = contacts.map((contact) => ({
    value: contact.phone,
    label: `${contact.firstname} ${contact.lastname}`,
  }));

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container apps-container apps-email">
          <div className="nxl-content without-header nxl-full-content ">
            <div className="main-content d-flex">
              <div
                className="content-area"
                data-scrollbar-target="#psScrollbarInit"
              >
                <div className="row m-3 mb-0">
                  <div className="mb-4 col-12 align-items-center justify-content-between">
                    <h5 className="fw-bold mb-0 me-4">
                      <span className="d-block mb-2">
                        {t("singleSms1")}
                      </span>
                      <span className="fs-12 fw-normal text-muted text-truncate-1-line">
                        {t("singleSms2")}.
                      </span>
                      <p className="text-primary m-0">
                        {t("singleSms3")}
                      </p>
                    </h5>
                  </div>
                </div>
                <div className="items-details-footer mail-action-editor m-4 border border-top-0 rounded-3 mb-3">
                  <div className="p-0 border-top position-relative editor-section bg-white">
                    <div className="position-relative border-bottom w-100 d-flex align-items-center">
                      <input
                        className="form-control border-0 shadow-none"
                        type="text"
                        placeholder={t("singleSms13")}
                        maxLength={20}
                        value={title}
                        onChange={handleTitleChange}
                      />
                      <span className="text-muted text-nowrap pe-3">
                        {title.length} / 20 {t("singleSms4")}
                      </span>
                    </div>
                    <div className="position-relative border-bottom d-flex align-items-center p-3 gap-3">
                      <div className="text-nowrap ">{t("singleSms5")}: </div>
                      <select
                        className="form-control text-dark"
                        onChange={handleSmsTypeChange}
                        value={selectedType?.name || ""}
                      >
                        <option value="" disabled>
                          {t("singleSms6")}
                        </option>
                        {smsType.map((type) => (
                          <option key={type.name} value={type.name}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="position-relative border-bottom d-flex align-items-center">
                      <Creatable<OptionType, true>
                        options={transformedContacts}
                        className="form-control border-0 text-dark rounded-0"
                        placeholder={t("singleSms11")}
                        isMulti
                        value={to}
                        onChange={handleToChange}
                        inputValue={inputValue}
                        onInputChange={(value) => setInputValue(value)}
                        onBlur={handleBlur}
                      />
                    </div>
                    <textarea
                      className="w-100 ht-150 border border-bottom-0 border-top"
                      placeholder={t("singleSms12")}
                      maxLength={500}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                    <div className="text-muted mt-2 px-3">
                      {message.length} / 500 {t("singleSms7")}
                    </div>
                    <div className="p-2 px-3 d-flex align-items-center justify-content-between border-top">
                      <button
                        className="btn btn-primary"
                        disabled={
                          submitting ||
                          title.length === 0 ||
                          to.length === 0 ||
                          selectedType === null ||
                          message.length === 0 ||
                          message.length > 500
                        }
                        onClick={handleSend}
                      >
                        {submitting && <span className="loader"></span>}
                        {t("singleSms8")}
                      </button>
                      <div className="dropdown d-none d-sm-block">
                        <button
                          data-bs-toggle="dropdown"
                          className="border-0 bg-transparent"
                        >
                          <span className="btn btn-icon">
                            <UilEllipsisV />
                          </span>
                        </button>
                        <div className="dropdown-menu wd-300">
                          <button className="dropdown-item">
                            <UilSave className="me-3" />
                            {t("singleSms9")}
                          </button>
                          <button className="dropdown-item">
                            <UilFileAlt className="me-3" />
                            {t("singleSms10")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default SingleSMS;
