import { MenuItem } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { getTranslatedPages } from "./Pages";
import { useTranslation } from "react-i18next";

interface FooterProps {
  onNavigate: (sectionId: string) => void;
}

const Footer: React.FC<FooterProps> = ({ onNavigate }) => {
  const { t, i18n } = useTranslation();
  const pages = getTranslatedPages(t);
  const handleLinkClick = (path: string) => {
    if (path === "#") {
      // Do nothing or handle special cases if necessary
    } else {
      onNavigate(path);
    }
  };
  return (
    <div className="footer">
      <footer className=" ">
        <div className="container">
          <div className="row justify-content-center border-top pt-3">
            <div className="d-flex justify-content-center flex-wrap">
              {pages.map((page) => (
                <MenuItem
                  key={page.path}
                  onClick={() => handleLinkClick(page.path)}
                >
                  <Link
                    key={page.path}
                    to={page.path}
                    className="text-black fs-16"
                    style={{ textDecoration: "none", color: "inherit" }}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default link behavior
                      handleLinkClick(page.path);
                    }}
                  >
                    {page.label}
                  </Link>
                </MenuItem>
              ))}
              <MenuItem>
                <Link
                  to={"/terms-and-conditions"}
                  className="text-black fs-16"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {t("terms_condition")}
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to={"/privacy-policy"}
                  className="text-black fs-16"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {t("policy")}
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to={"/faq"}
                  className="text-black fs-16"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {t("faq")}
                </Link>
              </MenuItem>
            </div>
            <div className="col-md-4 mx-auto d-flex justify-content-center">
              <img
                src="../assets/images/dark.png"
                alt="Logo Large"
                style={{ height: "100px" }}
              />
            </div>
            <h3 className="text-center my-3">{t("footer1")}</h3>
            <p className="text-black mb-0 text-center fs-16">
            {t("footer2")}
            </p>
            <button
              className="btn btn-primary col-8 col-md-3 my-3"
              onClick={() => window.open("/auth", "_blank")}
            >
              {t("footer3")}
            </button>
          </div>
        </div>
        <div className="border-top mt-0 mt-md-5">
          <div className="container">
            <div className="d-flex flex-column flex-md-row justify-content-between pt-2">
              <p className="text-center">
                Crafted with ❤ by{" "}
                <Link
                  to={"https://digitwace.com/"}
                  // className="text-black fs-16"
                >
                  DigitWace Cameroun Sarl
                </Link>
              </p>
              <p className="text-center">
                © Copyright 2025 NUFISMS. All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
