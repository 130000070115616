import React, { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Import jsPDF AutoTable plugin
import Papa from "papaparse";
import { UilFileExport } from "@iconscout/react-unicons";
import LoaderOverlay from "./Loader/LoaderOverlay";
import { Contact, ContactModel } from "../models/Contacts";
import { useTranslation } from "react-i18next";

type ContactExportProps = {
  contacts: ContactModel[] | Contact[];
};

const ContactExport = ({ contacts }: ContactExportProps) => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const exportToCSV = () => {
    setIsLoading(true);
    const csv = Papa.unparse(contacts);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "contacts.csv");
    document.body.appendChild(link);
    link.click();
    setIsLoading(false); // Hide loader after download
  };

  const exportToPDF = () => {
    setIsLoading(true);

    const doc = new jsPDF();
    doc.text("Contact List", 14, 10); // Title of the PDF

    // Define table columns and data rows
    const tableColumn = ["First Name", "Last Name", "Phone Number"];
    const tableRows: any[] = [];

    // Populate rows with contact data
    contacts.forEach((contact) => {
      const contactData = [
        contact.firstname || "N/A",
        contact.lastname || "N/A",
        contact.phone,
      ];
      tableRows.push(contactData);
    });

    // Add table to the PDF using autoTable
    (doc as any).autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20, // Starting y position for the table
      theme: "grid", // Optional: 'grid', 'striped', 'plain'
    });

    doc.save("contacts.pdf");
    setIsLoading(false); // Hide loader after download
  };

  return (
    <div>
      {/* Loader Overlay */}
      <LoaderOverlay isLoading={isLoading} />

      <div className="dropdown">
        <div
          className="btn btn-icon btn-light-brand"
          data-bs-toggle="dropdown"
          data-bs-offset="0, 10"
          data-bs-auto-close="outside"
        >
          <UilFileExport />
        </div>
        <div className="dropdown-menu dropdown-menu-end">
          <span className="dropdown-item cursor-pointer" onClick={exportToCSV}>
            {t("contactExport1")}
          </span>
          <span className="dropdown-item cursor-pointer" onClick={exportToPDF}>
          {t("contactExport2")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactExport;
