import React from "react";
import { UilSetting, UilEllipsisV } from "@iconscout/react-unicons";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";

type CreateSessionProps = {
  currentStep: number;
  isLoading: boolean;
  existingSession: boolean;
  isScanning: boolean;
  phoneNumber: string | undefined;
  base64Url: string | undefined;
  setPhoneNumber: (number: string | undefined) => void;
  handleInitializeSession: () => void;
  handleBackStep: () => void;
};

const CreateSession: React.FC<CreateSessionProps> = ({
  currentStep,
  isLoading,
  phoneNumber,
  setPhoneNumber,
  existingSession,
  isScanning,
  base64Url,
  handleInitializeSession,
  handleBackStep,
}) => {
  const { t, i18n } = useTranslation();
  const isPossible: boolean = phoneNumber
    ? isPossiblePhoneNumber(phoneNumber)
    : false;
  return (
    <div className="card mx-auto mt-3">
      <div className="card-body">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6">
            <h5 className="text-muted">{t("createSession1")}:</h5>
            <ul className="list-unstyled mt-3 activity-feed">
              <li className="feed-item feed-item-danger">
                {t("createSession2")} <strong>{t("createSession3")}</strong>
              </li>
              <li className="feed-item feed-item-warning">
                {t("createSession4")}
              </li>
              <li className="feed-item feed-item-primary">
                {t("createSession5")} <UilEllipsisV /> {t("createSession6")} <UilSetting /> {t("createSession7")}{" "}
                <strong>{t("createSession8")}</strong>
              </li>
              <li className="feed-item feed-item-info">
                {t("createSession9")} <strong>{t("createSession10")}</strong>
              </li>
              <li className="feed-item feed-item-success">
                {t("createSession11")}
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            {currentStep === 0 && (
              <div>
                {isLoading && (
                  <div className="alert alert-info mb-3">
                    <p className="m-0">
                      {t("createSession12")}
                    </p>
                  </div>
                )}
                <h5>{t("createSession13")}:</h5>
                <PhoneInput
                  placeholder="Enter phone number"
                  international
                  defaultCountry="CM"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  className="form-control"
                />
                {!isPossible && phoneNumber && (
                  <span className="fw-14 text-danger text-center">
                    {t("createSession14")}
                  </span>
                )}

                <button
                  disabled={isLoading || !phoneNumber || !isPossible}
                  className="btn btn-primary mt-3 mx-auto"
                  onClick={handleInitializeSession}
                >
                  {isLoading && <span className="loader"></span>}{" "}
                  {isLoading ? t("createSession19") : t("createSession20")}
                </button>
              </div>
            )}
            {currentStep === 1 && !existingSession && (
              <div className="step-content d-flex flex-column justify-content-center align-items-center gap-3 my-2">
                <div>
                  <h6>{t("createSession15")}:</h6>
                  <p className="text-center">
                    {t("createSession17")}
                  </p>
                </div>
                <div className="qr-placeholder">
                  {isScanning && (
                    <div className="qr-overlay">
                      <div className="qr-spinner"></div>
                      <div className="qr-text">{t("createSession17")}...</div>
                    </div>
                  )}
                  {base64Url ? (
                    <img
                      src={base64Url}
                      alt="Scan this QR code with WhatsApp"
                      style={{
                        opacity: isScanning ? 0.5 : 1,
                      }} // Optional: Dim the QR code when scanning
                    />
                  ) : (
                    "QR Code Not Available"
                  )}
                </div>

                <div className="d-flex mx-auto gap-2">
                  <button
                    onClick={handleBackStep}
                    className="btn btn-light-brand"
                    disabled={isScanning}
                  >
                    {t("createSession18")}
                  </button>
                  {/* <button
              onClick={() => setSessionInit(true)}
              className="btn btn-light-brand"
            >
              Next
            </button> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSession;
