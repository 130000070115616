import {
  UilAirplay,
  UilDollarSignAlt,
  UilUsersAlt,
  UilSetting,
  UilComment,
  UilCoins,
  UilWhatsapp,
  UilLightbulbAlt,
  UilMoneyBill,
  UilMonitor,
} from "@iconscout/react-unicons";

type MenuItem = {
  label: { translationKey: string };
  icon: JSX.Element;
  path: string;
  subMenu?: {
    label: { translationKey: string };
    path: string;
  }[];
};

export const menuItems: MenuItem[] = [
  {
    label: { translationKey: "menu.dashboard" },
    icon: <UilAirplay />,
    path: "/dashboard",
  },
  {
    label: { translationKey: "menu.pricing" },
    icon: <UilMoneyBill />,
    path: "/all-pricing",
    subMenu: [
      { 
        label: { translationKey: "menu.whatsappPackages" }, 
        path: "subscribe" 
      },
    ],
  },
  {
    label: { translationKey: "menu.messages" },
    icon: <UilComment />,
    path: "/direct-sms",
    subMenu: [
      { label: { translationKey: "menu.directSMS" }, path: "direct-sms" },
      { label: { translationKey: "menu.bulkSMSFile" }, path: "send-bulk" },
      { label: { translationKey: "menu.bulkSMSGroup" }, path: "send-bulk-group" },
      { label: { translationKey: "menu.whatsapp" }, path: "whatsapp" },
      { label: { translationKey: "menu.messagesHistory" }, path: "sms-historic" },
      { label: { translationKey: "menu.whatsappHistory" }, path: "whatsapp-history" },
    ],
  },
  {
    label: { translationKey: "menu.contacts" },
    icon: <UilUsersAlt />,
    path: "/contacts",
    subMenu: [
      { label: { translationKey: "menu.contactList" }, path: "contacts" },
      { label: { translationKey: "menu.contactsCreate" }, path: "contacts-create" },
      { label: { translationKey: "menu.contactsGroups" }, path: "contacts-group" },
    ],
  },
  {
    label: { translationKey: "menu.settings" },
    icon: <UilSetting />,
    path: "/settings",
    subMenu: [
      { label: { translationKey: "menu.account" }, path: "settings" },
    ],
  },
  {
    label: { translationKey: "menu.developers" },
    icon: <UilMonitor />,
    path: "/developers",
    subMenu: [
      { label: { translationKey: "menu.apiKeys" }, path: "apis" },
      {
        label: { translationKey: "menu.developerDocs" },
        path: "https://docs.digitwace.com/",
      },
    ],
  },
  {
    label: { translationKey: "menu.clients" },
    icon: <UilUsersAlt />,
    path: "/clients",
    subMenu: [
      { label: { translationKey: "menu.clientList" }, path: "client-list" },
      { label: { translationKey: "menu.createClient" }, path: "client-create" },
      { label: { translationKey: "menu.manageIPs" }, path: "manage-ip-addresses" },
    ],
  },
  {
    label: { translationKey: "menu.managePricing" },
    icon: <UilCoins />,
    path: "/manage-pricing",
    subMenu: [
      { label: { translationKey: "menu.allPricing" }, path: "/manage-pricing" },
      { label: { translationKey: "menu.addPricing" }, path: "/add-pricing" },
      { label: { translationKey: "menu.addCountry" }, path: "/coverage" },
      { label: { translationKey: "menu.whatsappPackages" }, path: "/packages-whatsapp" },
    ],
  },
  {
    label: { translationKey: "menu.administrator" },
    icon: <UilLightbulbAlt />,
    path: "/admin",
    subMenu: [
      { label: { translationKey: "menu.allAdministrators" }, path: "/admin" },
      { label: { translationKey: "menu.manageRoles" }, path: "/manage-roles" },
    ],
  },
];