import { useState, useEffect } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import toast from "react-hot-toast";
import { useNavigate} from "react-router-dom";
import { UilSave,UilAngleLeftB } from "@iconscout/react-unicons";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { packageService } from "../../services/packageService";
import { useTranslation } from "react-i18next";

type Props = {};

const UpdateClientPackage = (props: Props) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { balance } = useSelector((state: RootState) => state.account);
  const { currentUser } = useSelector((state: RootState) => state.auth);
  

  const [formData, setFormData] = useState({
    messages:"",
    extractions: "",
    totalAmount:"",
  });

  
  useEffect(() => {
    const messages = parseFloat(formData.messages) || 0;
    const extractions = parseFloat(formData.extractions) || 0;
    const totalAmount = (messages * 0.0003) + (extractions * 1);

    setFormData((prevData) => ({
      ...prevData,
      totalAmount: totalAmount.toString(), 
    }));
  }, [formData.messages, formData.extractions]);

  const handleChange = (field: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
    
  const handleUpdatePackage = async () => {
    try {
      setIsLoading(true);
      
      if (balance !== undefined && Number(balance) < parseFloat(formData.totalAmount)) {
        toast.error('Insufficient balance to customize subscription');
      }else{
        const data = {
          additionalMessages: Number(formData.messages),
          additionalExtractions: Number(formData.extractions),
        };
        const result = await packageService.customizeSubscription(
          data
        );
        if (result.status === -1) {
          toast.error(result.message);
        } else {
          toast.success(result.message);
          navigate("/update-package");
        }
      }
     
    } catch (error: any) {
      toast.error(`Update failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <AuthLayout>
      <VerifiedGuard>
         <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
                <div className="page-header-left d-flex align-items-center">
                    <div className="page-header-title">
                        <h5 className="m-b-10 border-0">{t("updateClienPackage1")}</h5>
                    </div>
                </div>
                <div className="page-header-right ms-auto">
                    <div className="page-header-right-items">
                        <div className="d-flex d-md-none">
                        <div className="page-header-right-close-toggle">
                            <UilAngleLeftB className="me-2" />
                            <span>{t("updateClienPackage2")}</span>
                        </div>
                        </div>
                        <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                        <button
                            className="btn btn-primary"
                            onClick={() => navigate(-1)}
                        >
                            <span>{t("updateClienPackage3")}</span>
                        </button>
                        </div>
                    </div>
                    <div className="d-md-none d-flex align-items-center">
                        <div className="page-header-right-open-toggle">
                        <i className="feather-align-right fs-20"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-content">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-10 ">
                  <div className="card stretch stretch-full">
                    <div className="card-body">
                      <form 
                       onSubmit={(e) => {
                        e.preventDefault();
                        handleUpdatePackage();
                      }}
                      >
                        <div className="row">
                          <div className="mb-3 col-md-4">
                          <label htmlFor="messages">{t("updateClienPackage4")}</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder={t("updateClienPackage4")}
                              id="messages"
                              min={1}
                              value={formData.messages}
                              onChange={(e) =>
                                handleChange("messages", e.target.value)
                              }
                            />
                          </div>
                          <div className="mb-3 col-md-4">
                          <label htmlFor="extractions">{t("updateClienPackage5")}</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder={t("updateClienPackage5")}
                              id="extractions"
                              min={1}
                              value={formData.extractions}
                              onChange={(e) =>
                                handleChange("extractions", e.target.value)
                              }
                            />
                          </div>
                          <div className="mb-3 col-md-4">
                          <label htmlFor="extractions">{t("updateClienPackage6")} (€)</label>
                            <input
                              type="number"
                              className="form-control"
                              readOnly
                              placeholder="Remaining Extractions"
                              id="totalAmount"
                              min={1}
                              value={formData.totalAmount}
                              onChange={(e) =>
                                handleChange("totalAmount", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                         <button
                            type="submit"
                            disabled={(!formData.messages || Number(formData.messages) <= 0) && 
                                      (!formData.extractions || Number(formData.extractions) <= 0)}
                            className={`btn btn-primary
                            ${!currentUser?.activeSubscriptions ? "disabled" : ""}`}
                          >
                            {isLoading && <span className="loader"></span>}
                            <UilSave />{t("updateClienPackage7")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default UpdateClientPackage;


