import React, { useEffect, useRef, useState } from "react";
import { UilTimes } from "@iconscout/react-unicons";
import Creatable from "react-select/creatable";
import toast from "react-hot-toast";
import { Contact } from "../../models/Contacts";
import { whatsappService } from "../../services/whatsappService";
import Select from "react-select";
import {
  parseCSVFile,
  parseExcelFile,
  parseTextFile,
} from "../../utils/fileParsers";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getAllWhatsappContacts } from "../../redux/slices/contactSlice";
import { customStyles, OptionType } from "../../pages/app/SingleSMS";
import { logMessage } from "../../utils/logMessage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formatForWhatsApp, getTextFromHtml } from "../../utils/functions";
import ImageUpload from "./ImageUpload";
import { useTranslation } from "react-i18next";

type WhatsappMessageProps = {
  phoneNumber: string | undefined;
};

const WhatsappMessage: React.FC<WhatsappMessageProps> = ({ phoneNumber }) => {
  const {t, i18n} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = React.useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [uploadedContacts, setUploadedContacts] = useState<Contact[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<OptionType[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [editorHtml, setEditorHtml] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedContactType, setSelectedContactType] = useState<OptionType | null>(null);
  const [contactUse, setContactUse] = useState<Contact[]>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const quillRef = useRef<ReactQuill>(null);
  const dispatch: AppDispatch = useDispatch();

  const contacts = useSelector(
    (state: RootState) => state.contacts.whatsappContacts
  );

  const handleImageChange = (file: File | null) => {
    setSelectedImage(file);
  };

  const contactTypeOptions = [
    { value: "phone", label: t("whatsappMessage1") },
    { value: "extract", label: t("whatsappMessage2") },
  ];

  const handleContactTypeChange = async (selectedOption: any | null) => {
    setSelectedContactType(selectedOption);
  
    if (selectedOption?.value === "extract") {
      setContactUse(contacts);
    } else if (selectedOption?.value === "phone") {
      try {
        if (phoneNumber) {
          const cleanPhoneNumber = phoneNumber.replace(/^\+/, "");
          const getWhatsappContact = await whatsappService.getWhatsappContact(cleanPhoneNumber);
          setContactUse(getWhatsappContact.data);
        }
      } catch (error) {
        logMessage("Error fetching WhatsApp contacts:");
        toast.error("Failed to fetch WhatsApp contacts");
      }
    }
  };

  const isEditorEmpty = (html: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    // Remove whitespace and check if there's any meaningful text
    const textContent = tempDiv.textContent || "";

    // Remove HTML tags and check if the remaining content is empty
    return (
      textContent.trim() === "" ||
      textContent.replace(/[\n\r]+/g, "").trim() === ""
    );
  };

  const handleSubmit = async () => {
    // Early returns for invalid input
    if (!phoneNumber) {
      toast.error("Invalid Phone Number");
      return;
    }
    if (!editorHtml) {
      toast.error("Please enter your message content");
      return;
    }
    if (isUploaded && !file) {
      toast.error("Please select a file containing your contacts.");
      return;
    }
    if (!isUploaded && selectedContacts.length === 0) {
      toast.error("You need to select contacts.");
      return;
    }

    // Optimize contact lookup using Map for faster lookups
    const contactLookup = new Map(
      contacts
        .filter((contact) => contact.phone)
        .map((contact) => [contact.phone, contact])
    );

    // Format the phone number by removing the '+' sign
    const formattedPhoneNumber = phoneNumber.startsWith("+")
      ? phoneNumber.slice(1)
      : phoneNumber;

    // Prepare formatted contacts
    const formattedContacts = selectedContacts.map((option: OptionType) => {
      const contact = contactLookup.get(option.value) as Contact | undefined; // Type assertion for contact

      return {
        firstname: contact?.firstname || "", // Use optional chaining
        lastname: contact?.lastname || "",
        email: contact?.email || "",
        phone: option.value,
      };
    });

    const toContacts =
      uploadedContacts.length > 0 ? uploadedContacts : formattedContacts;
    const formattedMessage = formatForWhatsApp(editorHtml);

    try {
      setIsLoading(true);

      // Create data object for the request
      const data = {
        phoneNumber: formattedPhoneNumber,
        message: formattedMessage,
        contactsObj: toContacts,
      };

      let response;

      if (selectedImage) {
        // Create FormData only if there's a file to upload
        const formData = new FormData();
        formData.append("message", formattedMessage);
        formData.append("phoneNumber", formattedPhoneNumber);
        formData.append("contactsObj", JSON.stringify(toContacts));
        formData.append("image", selectedImage);

        response = await whatsappService.sendMessageWithImage(formData);
      } else {
        // Send regular message without file
        response = await whatsappService.sendMessageToContacts(data);
      }

      // Handle response
      if (response.status === -1) {
        toast.error(response.message);
      } else {
        toast.success(response.message);
        resetForm(); // Batch state updates by moving them into one function
      }
    } catch (error) {
      toast.error("An error occurred while sending your message");
      logMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to reset form fields
  const resetForm = () => {
    setEditorHtml("");
    setUploadedContacts([]);
    setSelectedContacts([]);
    setIsUploaded(false);
    setSelectedImage(null);
  };

  const handleChange = (content: string) => {
    const textContent = getTextFromHtml(content);
    if (textContent.length <= 1000) {
      setEditorHtml(content);
    } else {
      setEditorHtml(content.substring(0, 1000)); // Trim excess characters
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }

    try {
      let data: Contact[] = [];

      if (file.type === "text/csv") {
        data = await parseCSVFile(file);
      } else if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        data = await parseExcelFile(file);
      } else if (file.type === "text/plain") {
        data = await parseTextFile(file);
      }

      setUploadedContacts(data);
      setIsUploaded(true);
    } catch (error) {
      logMessage("Error parsing file");
    }

    // Clear the file input value to ensure the change event is fired for the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDiscard = () => {
    setUploadedContacts([]);
    setIsUploaded(false);
    setFile(null);
  };

  const transformContactsToOptions = (contacts: Contact[]): any[] => {
    return contacts
      .filter((contact) => contact.phone) 
      .map((contact) => ({
        value: contact.phone,
        // label: `${contact.firstname ?? "N/A"} - ${contact.phone}`,
        label: `${contact.phone}`,
      }));
  };


  const transformedContacts = transformContactsToOptions(contactUse ?? []);


  useEffect(() => {
    dispatch(getAllWhatsappContacts());
  }, [dispatch, contactUse]);


  // Add the input value as a new contact if necessary
  const handleBlur = () => {
    if (inputValue.trim()) {
      const newOption: OptionType = {
        label: inputValue,
        value: inputValue,
      };
      setSelectedContacts((prev) => [...prev, newOption]);
      setInputValue(""); // Reset input value after creation
    }
  };

  return (
    <div className="row">
      <div className="mb-4 col-12 align-items-center justify-content-between">
        <h5 className="fw-bold mb-0 me-4">
          <span className="d-block mb-2">
            {t("whatsappMessage3")}.
          </span>
          <span className="fs-12 fw-normal text-muted text-truncate-1-line">
            {t("whatsappMessage4")}.
          </span>
          <p className="text-primary m-0">
            {t("whatsappMessage5")}
          </p>
        </h5>
      </div>
      <div className="step-content col-md-8">
        <div className="d-flex flex-column gap-2">
          <div className="text-primary fw-bold">
            {uploadedContacts.length !== 0
              ? "Note : Only uploaded contacts will be considered"
              : ""}
          </div>
          <div className="contact">
            <div className="mb-2">
            <Select<OptionType>
                options={contactTypeOptions}
                className="text-dark"
                placeholder={t("whatsappMessage6")}
                styles={customStyles}
                value={selectedContactType}
                onChange={handleContactTypeChange}
              />
            </div>
          <Creatable<OptionType, true>
            options={transformedContacts}
            className=" text-dark"
            placeholder={t("whatsappMessage7")}
            styles={customStyles}
            isDisabled={uploadedContacts.length !== 0}
            id="to"
            isMulti
            name="to"
            value={selectedContacts}
            onChange={(option) => setSelectedContacts(option as OptionType[])}
            inputValue={inputValue}
            onInputChange={(value) => setInputValue(value)}
            onBlur={handleBlur} // Trigger adding the option when clicking outside
          />
          </div>
          <h6 className="text-center mb-0">{t("whatsappMessage8")}</h6>
          <div className="d-flex border align-items-center">
            <input
              type="file"
              name="upload"
              id="upload"
              className="p-2 w-100"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept=".csv, .xlsx, .xls, .txt"
            />
            {isUploaded && (
              <UilTimes
                className="border me-1 cursor-pointer"
                onClick={handleDiscard}
              />
            )}
          </div>
        </div>
        {isUploaded && (
          <p className="text-start text-primary fw-bold">
            {uploadedContacts.length} {t("whatsappMessage9")}
          </p>
        )}
        <div className="items-details-footer mail-action-editor mt-3 border border-top-0 rounded-3">
          <div
            className="p-0  border-top position-relative editor-section"
            data-scrollbar-target="#psScrollbarInit"
          >
            <div className="h-100 border-0" id="mailEditor">
              <ReactQuill
                ref={quillRef}
                value={editorHtml}
                onChange={handleChange}
                className="h-100"
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["clean"],
                  ],
                }}
                formats={["header", "bold", "italic", "list"]}
              />
            </div>
          </div>
        </div>
        <div className="text-muted mt-2 text-end">
          {getTextFromHtml(editorHtml).length} / 1000 {t("whatsappMessage10")}
        </div>
        <div className="d-flex align-items-center gap-2 mt-3 justify-content-between">
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={
              isLoading ||
              isEditorEmpty(editorHtml) ||
              (selectedContacts.length === 0 && uploadedContacts.length === 0)
            }
          >
            {isLoading && <span className="loader"></span>}
            {isLoading ? t("whatsappMessage12") : t("whatsappMessage11")}
          </button>
        </div>
      </div>
      <div className="col-md-4 py-2">
        <ImageUpload
          onFileChange={handleImageChange}
          selectedImage={selectedImage}
        />
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/mZr2GsLI8PM"
          title="WhatsApp Messaging Tutorial"
          style={{ height: "200px" }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default WhatsappMessage;