import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {};

const PrivacyPolicy = (props: Props) => {
  const {t} = useTranslation();
  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center">
        <Link to={"/"}>
          <img
            src="../assets/images/dark.png"
            alt="Logo Large"
            style={{ height: "70px" }}
          />
        </Link>
      </div>
      <div className="mt-5">
        <h2 className="text-center mb-4">
          <strong>{t("privacyPolicy1")}</strong>
        </h2>
        <p className="text-justify">
         {t("privacyPolicy2")} <strong>NufiSMS</strong>. {t("privacyPolicy3")}.
        </p>

        <h3 className="mt-5 mb-4">
          <strong>{t("privacyPolicy4")}</strong>
        </h3>
        <p className="text-justify">
          {t("privacyPolicy5")}:
        </p>
        <ul>
          <li>
            <strong>{t("privacyPolicy6")}:</strong> {t("privacyPolicy7")}.
          </li>
          <li>
            <strong>{t("privacyPolicy8")}:</strong> {t("privacyPolicy9")}
          </li>
          <li>
            <strong>{t("privacyPolicy10")}:</strong> {t("privacyPolicy11")}
          </li>
        </ul>

        <h3 className="mt-5 mb-4">
          <strong>{t("privacyPolicy12")}</strong>
        </h3>
        <p className="text-justify">{t("privacyPolicy13")}:</p>
        <ul>
          <li>
            {t("privacyPolicy14")}
          </li>
          <li>
            {t("privacyPolicy15")}
          </li>
          <li>
           {t("privacyPolicy16")}
          </li>
          <li>
            {t("privacyPolicy17")}
          </li>
        </ul>

        <h3 className="mt-5 mb-4">
          <strong>{t("privacyPolicy18")}</strong>
        </h3>
        <p className="text-justify">
          {t("privacyPolicy19")}
        </p>
        <ul>
          <li>
            {t("privacyPolicy20")}
          </li>
          <li>
           {t("privacyPolicy21")}
          </li>
          <li>
            {t("privacyPolicy22")}
          </li>
        </ul>

        <h3 className="mt-5 mb-4">
          <strong>{t("privacyPolicy23")}</strong>
        </h3>
        <p className="text-justify">{t("privacyPolicy24")}</p>
        <ul>
          <li>
            <strong>{t("privacyPolicy25")}</strong> {t("privacyPolicy26")}
          </li>
          <li>
            <strong>{t("privacyPolicy27")}</strong> {t("privacyPolicy28")}
          </li>
          <li>
            <strong>{t("privacyPolicy29")}</strong> {t("privacyPolicy30")}
          </li>
        </ul>

        <h3 className="mt-5 mb-4">
          <strong>{t("privacyPolicy31")}</strong>
        </h3>
        <p className="text-justify">{t("privacyPolicy32")}</p>
        <ul>
          <li>
            <strong>{t("privacyPolicy33")}</strong> {t("privacyPolicy34")}
          </li>
          <li>
            <strong>{t("privacyPolicy35")}</strong> {t("privacyPolicy36")}
          </li>
          <li>
            <strong>{t("privacyPolicy37")}</strong> {t("privacyPolicy38")}
          </li>
          <li>
            <strong>Opt-Out:</strong> {t("privacyPolicy39")}
          </li>
        </ul>

        <h3 className="mt-5 mb-4">
          <strong>{t("privacyPolicy40")}</strong>
        </h3>
        <p className="text-justify">
          {t("privacyPolicy41")}
        </p>

        <h3 className="mt-5 mb-4">
          <strong>{t("privacyPolicy42")}</strong>
        </h3>
        <p className="text-justify">
          {t("privacyPolicy43")}
        </p>

        <h3 className="mt-5 mb-4">
          <strong>{t("privacyPolicy44")}</strong>
        </h3>
        <p className="text-justify">
          {t("privacyPolicy45")}
        </p>
        <p className="text-justify">
          <strong>{t("privacyPolicy46")}:</strong> support@nuifisms.com
        </p>
        <p className="text-justify">
          <strong>{t("privacyPolicy47")}</strong> {t("privacyPolicy48")}
        </p>
      </div>
      <div className="mt-5 d-flex flex-column flex-md-row justify-content-between pt-2 border-top">
        <p className="text-center">
          Crafted with ❤ by{" "}
          <Link
            to={"https://digitwace.com/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            DigitWace Cameroun Sarl
          </Link>
        </p>
        <p className="text-center">
          © Copyright 2024 NufiSMS. All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
