import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { AuthService } from "../../services/authService";
import { useTranslation } from "react-i18next";

type Props = {};

const ForgotPassword = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "", // Initialize with an empty string
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email").required("Email is required"),
    }),
    onSubmit: async () => {
      if (submitting) return;
      setSubmitting(true);
      const email = formik.values.email;
      try {
        const res = await AuthService.confirmEmail({ email });

        if (res.status === -1) {
          toast.error(res.message);
          setSubmitting(false);
          return;
        }

        toast.success("Request Successful! Please check your email inbox", {
          duration: 6000,
        });
        localStorage.setItem("email", res.data.email);
        setSendRequest(true);
      } catch (err: any) {
        const error =
          err.response?.data?.message ||
          "An error occurred. Please try again later.";
        toast.error(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <main className="auth-minimal-wrapper">
      <div className="d-flex flex-column align-items-center gap-3 p-3">
        <img
          src="../assets/images/dark.png"
          alt="nufiSMS logo"
          style={{ height: "100px" }}
        />
        <div className="card stretch stretch-full col-md-4 mx-auto">
          <div className="card-body p-sm-5">
            {sendRequest ? (
              <div>
                <h5 className="text-success text-center">
                  {t("forgotPassword1")}
                </h5>
                <h6 className="text-center">{t("forgotPassword2")}</h6>
                <div className="mt-5 text-muted text-center">
                  <span> {t("forgotPassword3")}</span>
                  <span
                    className="fw-bold text-primary cursor-pointer"
                    onClick={() => formik.handleSubmit()}
                  >
                    {submitting && <span className="loader"></span>}
                    {submitting ? "Resending..." : "Resend"}
                  </span>
                </div>
              </div>
            ) : (
              <>
                <h3 className="text-center">{t("forgotPassword4")}</h3>
                <p className="fs-12 fw-medium text-muted text-center">
                  {t("forgotPassword5")}
                </p>
                <form
                  className="w-100 mt-4 pt-2"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="mb-4">
                    <input
                      className="form-control"
                      placeholder="Email"
                      required
                      id="email"
                      type="email"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger">{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div className="mt-5">
                    <button
                      type="submit"
                      disabled={submitting || !formik.isValid}
                      className="btn btn-lg btn-primary w-100"
                    >
                      {submitting && <span className="loader"></span>}
                      {t("forgotPassword6")}
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword;
