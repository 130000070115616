import { useEffect, useState } from "react";
import {
  UilAngleLeftB,
  UilPaperclip,
  UilSetting,
  UilPlus,
  UilSearch,
} from "@iconscout/react-unicons";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { Client } from "../../models/Client";
import { adminServices } from "../../services/adminService";
import { useTranslation } from "react-i18next";

type Props = {};

const ClientList = (props: Props) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Client[]>([]);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [limit, setLimit] = useState<number | "all">(5); 
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate();

  const handleDelete = async () => {};
  const handleManage = async (client: Client) => {
    setSelectedClient(client);
    navigate(`/client-details/${client.user_code}`);
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const params = {
        include: "sms",
        page: 1,
        limit: limit === "all" ? 99999 : limit, 
      };
      await adminServices
        .getClients(params.include, params.page, params.limit)
        .then((res: any) => {
          if (res.status === -1) {
            return;
          }
          setUsers(res.data);
        })
        .catch((err: any) => {
          toast.error("Failed to fetch clients. Please try again later.");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error(
        "An error occurred while fetching clients. Please try again later."
      );
    }
  };

  const getStatusBadgeClass = (status: any) => {
    switch (status) {
      case "Account_created":
        return "bg-soft-warning text-warning";
      case "Account_active":
        return "bg-soft-success text-success";
      case "Account_desactivated":
        return "bg-soft-danger text-danger";
      default:
        return "bg-soft-secondary text-secondary";
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [limit]);

  const filteredUsers = users.filter((user) => {
    const query = searchQuery.toLowerCase();
    return (
      user.firstname.toLowerCase().includes(query) ||
      user.lastname.toLowerCase().includes(query) ||
      user.status.toLowerCase().includes(query) ||
      user.status.toLowerCase().includes(query) ||
      moment(user.updatedAt).format("DD/MM/YYYY HH:mm").toLowerCase().includes(query)
    );
  });

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">{t("clientList1")}</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>{t("clientList2")}</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                    <div className="dropdown">
                      <div
                        className="btn btn-icon btn-light-brand"
                        data-bs-toggle="dropdown"
                        data-bs-offset="0, 10"
                        data-bs-auto-close="outside"
                      >
                        <UilPaperclip />
                      </div>
                      <div className="dropdown-menu dropdown-menu-end">
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-pdf me-3"></i>
                          <span>PDF</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-csv me-3"></i>
                          <span>CSV</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-xml me-3"></i>
                          <span>XML</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-txt me-3"></i>
                          <span>Text</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-exe me-3"></i>
                          <span>Excel</span>
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item">
                          <i className="bi bi-printer me-3"></i>
                          <span>{t("clientList3")}</span>
                        </div>
                      </div>
                    </div>
                    <Link className="btn btn-primary" to={"/client-create"}>
                      <UilPlus />
                      <span>{t("clientList4")}</span>
                    </Link>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <div className="page-header-right-open-toggle">
                    <i className="feather-align-right fs-20"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
            <div className="d-flex justify-content-between align-items-center mb-1 ">
            <div style={{ position: "relative" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search ..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ paddingLeft: "40px" }} 
              />
              <div
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                }}
              >
                <UilSearch className="text-muted" />
              </div>
            </div>
            <div>
              <select
                className="form-select"
                style={{ width: "auto" }}
                value={limit}
                onChange={(e) =>
                  setLimit(
                    e.target.value === "all"
                      ? "all"
                      : Number(e.target.value)
                  )
                }
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value="all">{t("clientList5")}</option>
              </select>
            </div>
            </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card stretch stretch-full">
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-hover" id="customerList">
                          <thead>
                            <tr>
                              <th className="wd-30">
                                <div className="btn-group mb-1">
                                  <div className="custom-control custom-checkbox ms-1">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="checkAllCustomer"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="checkAllCustomer"
                                    ></label>
                                  </div>
                                </div>
                              </th>
                              <th>{t("clientList6")}</th>
                              <th>{t("clientList7")}</th>
                              <th>{t("clientList8")}</th>
                              <th>{t("clientList9")}</th>
                              <th>{t("clientList10")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsers &&
                              filteredUsers.map((user, index) => (
                                <tr key={index} className="single-item">
                                  <td>
                                    <div className="item-checkbox ms-1">
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input checkbox"
                                          id="checkBox_1"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="checkBox_1"
                                        ></label>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="text-truncate-1-line">
                                      {user.firstname}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      <div>
                                        <span className="text-truncate-1-line">
                                          {user.lastname}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      {moment(user.updatedAt).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <span
                                      className={`badge ${getStatusBadgeClass(
                                        user.status
                                      )}`}
                                    >
                                      {user.status}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-3 align-items-center">
                                      <button
                                        className="btn btn-primary btn-light-brand py-1"
                                        onClick={() => handleManage(user)}
                                      >
                                        <UilSetting />
                                        <span>{t("clientList11")}</span>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {users?.length === 0 && (
                          <h5 className="text-center my-5">
                            {t("clientList12")}
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default ClientList;