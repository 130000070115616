import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { SMS, SMSStatus } from "../../models/SMS";
import {
  UilArrowLeft,
  UilArrowCircleRight,
  UilAlignRight,
  UilAngleLeftB,
} from "@iconscout/react-unicons";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchMessages } from "../../redux/slices/whatsappSlice";
import { getStatusBadgeClass } from "../../utils/functions";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {};

export const truncateContent = (content: string, maxLength: number) => {
  return content.length > maxLength
    ? content.slice(0, maxLength) + "..."
    : content;
};

const WhatsappHistory = (props: Props) => {
  const {t, i18n} = useTranslation();
  const [selectedMessage, setSelectedMessage] = useState<SMS | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [filters, setFilters] = useState<{
    content?: string;
    status?: SMSStatus;
  }>({});
  const [searchParams, setSearchParams] = useSearchParams(); // Use this to update the query params

  const dispatch: AppDispatch = useDispatch();
  const { messages } = useSelector((state: RootState) => state.whatsapp);

  useEffect(() => {
    dispatch(
      fetchMessages({
        page: currentPage,
        limit: itemsPerPage,
        filters,
      })
    );
  }, [currentPage, dispatch, filters, itemsPerPage]);

  // Set selected message based on the "code_msg" param in the URL
  useEffect(() => {
    const code_msg = searchParams.get("message");
    if (code_msg && messages) {
      const foundMessage = messages.find(
        (message) => message.code_msg === code_msg
      );
      if (foundMessage) {
        setSelectedMessage(foundMessage);
      }
    }
  }, [messages, searchParams]);

  const handleRowClick = (message: SMS) => {
    setSelectedMessage(message);
    setSearchParams({ message: message.code_msg }); // Update the URL param with message code
  };

  const handleBackClick = () => {
    setSelectedMessage(null);
    setSearchParams({}); // Remove the "message" param from the URL
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page
  };

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">{t("whatsappHistory1")}</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>{t("whatsappHistory2")}</span>
                    </div>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <div className="page-header-right-open-toggle">
                    <UilAlignRight />
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="d-flex p-3 mb-2 w-100 justify-content-end align-items-center">
                <span>{t("whatsappHistory3")} :</span>
                <select
                  name="items"
                  id="items"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  className="ms-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                </select>
              </div>
              <div className="row">
                <div className="col-xxl-8 mx-auto">
                  <div className="card stretch stretch-full">
                    {selectedMessage ? (
                      <div>
                        <div className="card-header">
                          <button
                            onClick={handleBackClick}
                            className="btn text-black border-0 bg-transparent"
                          >
                            <UilArrowLeft /> {t("whatsappHistory4")}
                          </button>
                          <h5 className="card-title text-center">
                          {t("whatsappHistory5")}
                          </h5>
                        </div>
                        <div className="card-body">
                          <h6>{t("whatsappHistory6")}: {selectedMessage.code_msg}</h6>
                          <div className="d-flex align-items-center mb-3 gap-2">
                            <h6 className="m-0">{t("whatsappHistory7")}: </h6>
                            <p className="m-0"> {selectedMessage.smsContent}</p>
                          </div>

                          <h6>{t("whatsappHistory8")}</h6>
                          <div className="table-responsive">
                            <table className="table table-hover mb-0">
                              <thead>
                                <tr className="border-b">
                                  <th scope="row">{t("whatsappHistory9")}</th>
                                  <th>{t("whatsappHistory10")}</th>
                                  <th>{t("whatsappHistory11")}</th>
                                  <th>{t("whatsappHistory12")}</th>
                                  <th>{t("whatsappHistory13")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedMessage.contactsJsonParsed &&
                                  selectedMessage.contactsJsonParsed.map(
                                    (contact: any, index: number) => (
                                      <tr key={index}>
                                        <td>{contact.firstname || "N/A"}</td>
                                        <td>{contact.lastname || "N/A"}</td>
                                        <td>{contact.email || "N/A"}</td>
                                        <td>{contact.phone}</td>
                                        <td>
                                          <span
                                            className={`badge ${
                                              contact.status
                                                ? "bg-soft-success text-success"
                                                : "bg-soft-danger text-danger"
                                            }`}
                                          >
                                            {contact.status
                                              ? "Sent"
                                              : "Not Sent"}
                                          </span>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="card-header">
                          <h5 className="card-title">
                            {t("whatsappHistory14")}
                          </h5>
                        </div>
                        <div className="card-body custom-card-action p-0">
                          <div className="table-responsive">
                            <table className="table table-hover mb-0">
                              <thead>
                                <tr className="border-b">
                                  <th scope="row">{t("whatsappHistory15")}</th>
                                  <th>{t("whatsappHistory16")}</th>
                                  <th>{t("whatsappHistory17")}</th>
                                  <th>{t("whatsappHistory18")}</th>
                                  <th>{t("whatsappHistory19")}</th>
                                  <th>{t("whatsappHistory20")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {messages && messages.length > 0 ? (
                                  messages.map((message: SMS) => (
                                    <tr key={message.id}>
                                      <td>{message.code_msg}</td>
                                      <td>
                                        {truncateContent(
                                          message.smsContent,
                                          20
                                        )}
                                      </td>
                                      <td>
                                        {moment(message.timestamp).format(
                                          "DD/MM/YYYY HH:mm"
                                        )}
                                      </td>
                                      <td>
                                        <span
                                          className={`badge ${getStatusBadgeClass(
                                            message.status as SMSStatus
                                          )}`}
                                        >
                                          {message.status}
                                        </span>
                                      </td>
                                      <td>
                                        {message.isScheduled
                                          ? moment(message.sendTime).format(
                                              "DD/MM/YYYY HH:mm"
                                            )
                                          : "N/A"}
                                      </td>
                                      <td
                                        onClick={() => handleRowClick(message)}
                                      >
                                        <button className="bg-transparent border-0 ms-1">
                                          <UilArrowCircleRight className="text-secondary fs-2" />
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={6} className="text-center">
                                    {t("whatsappHistory21")}.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default WhatsappHistory;
