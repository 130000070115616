import React from "react";
import { UilImagePlus, UilTimes } from "@iconscout/react-unicons";
import { useTranslation } from "react-i18next";

type ImageUploadProps = {
  onFileChange: (file: File | null) => void;
  selectedImage: File | null;
  maxSize?: number;
};

const ImageUpload: React.FC<ImageUploadProps> = ({
  onFileChange,
  selectedImage,
  maxSize = 2 * 1024 * 1024,
}) => {
  const {t} = useTranslation();
  const [previewImage, setPreviewImage] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null); // Add a ref to the file input

  React.useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setPreviewImage(null);
    }
  }, [selectedImage]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      if (file.size > maxSize) {
        setError("File size exceeds 2MB limit");
        setPreviewImage(null);
        onFileChange(null);
        return;
      }

      setError(null);
      onFileChange(file);
    }
  };

  const handleRemoveImage = () => {
    onFileChange(null); // Discard the image
    setPreviewImage(null); // Clear the preview
    setError(null); // Clear any errors
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the input field value
    }
  };

  return (
    <div className="card border-0 file-upload bg-gray-100 p-2">
      {previewImage ? (
        <div className="image-preview w-100 position-relative">
          <img src={previewImage} alt="Selected" className="img-fluid w-100" />
          {/* X icon to remove image */}
          <button
            className="position-absolute bg-white p-1 m-1 top-0 end-0 btn btn-link text-danger"
            style={{ fontSize: "1.5rem" }}
            onClick={handleRemoveImage}
            aria-label="Remove image"
          >
            <UilTimes />
          </button>
          {/* Change Image button */}
          <button
            className="btn btn-primary btn-sm mt-2"
            onClick={() => fileInputRef.current?.click()}
          >
            {t("ImageUpload1")}
          </button>
        </div>
      ) : (
        <div
          className="rounded-circle border-2 ht-200 wt-200 mx-auto d-flex justify-content-center align-items-center flex-column cursor-pointer"
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          title="Pick an Image"
          onClick={() => fileInputRef.current?.click()}
        >
          <UilImagePlus size="50" className="text-primary title-icons" />
          <h5>{t("ImageUpload2")}</h5>
        </div>
      )}

      <input
        ref={fileInputRef} // Use the ref to track the input field
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        style={{ display: "none" }}
      />

      {error && <div className="text-danger mt-2">{error}</div>}
    </div>
  );
};

export default ImageUpload;
