import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import Select from "react-select";
import { adminServices } from "../../services/adminService";
import toast from "react-hot-toast";
import { UilEdit, UilTrashAlt, UilSetting } from "@iconscout/react-unicons";
import CreateRoleForm from "../../components/CreateRoleForm";
import { useTranslation } from "react-i18next";

type Props = {};
const options = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];
export type RoleData = {
  name: string;
  code_role: string;
  status: boolean;
};

const ManageRoles = (props: Props) => {
  const {t} = useTranslation();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [isloading, setIsloading] = React.useState(false);

  const handleEdit = async () => {
    if (!selectedRole) return;
    setIsloading(true);
    try {
      await adminServices
        .editRole(selectedRole.id, selectedRole)
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }
          toast.success(res.message);
          getAllRoles();
        })
        .catch((res: any) => toast.error(res.message))
        .finally(() => setIsloading(false));
    } catch (error) {
      toast.error("An error occured during role edit");
    }
  };
  const handleDelete = async () => {
    if (!selectedRole) return;
    setIsloading(true);
    try {
      await adminServices
        .deleteRole(selectedRole.id)
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }
          toast.success(res.message);
          getAllRoles();
        })
        .catch((res: any) => toast.error(res.message))
        .finally(() => setIsloading(false));
    } catch (error) {
      toast.error("An error occured during role delete");
    }
  };

  const getAllRoles = async () => {
    try {
      await adminServices
        .getRoles()
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }

          setRoles(res.data);
        })
        .catch((err) => {});
    } catch (error) {
      toast.error("COuld not get roles. Please try again later");
    }
  };
  useEffect(() => {
    getAllRoles();
  }, []);
  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">{t("manageRoles1")}</h5>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <CreateRoleForm
                  isloading={isloading}
                  setIsloading={setIsloading}
                  getAllRoles={getAllRoles}
                />
                <div className="col-lg-8">
                  <div className="card stretch stretch-full">
                    <div className="card-header">{t("manageRoles2")}</div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-hover" id="customerList">
                          <thead>
                            <tr>
                              <th>{t("manageRoles3")}</th>
                              <th>{t("manageRoles4")}</th>
                              <th>{t("manageRoles5")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {roles.length !== 0 &&
                              roles
                                .filter(
                                  (role: any) => role.name !== "superadmin"
                                )
                                .map((role: any, index) => (
                                  <tr key={index}>
                                    <td className="fw-bold">{role.name}</td>
                                    <td
                                      className={
                                        role.status
                                          ? "text-success"
                                          : "text-warning"
                                      }
                                    >
                                      {role.status ? "Active" : "Inactive"}
                                    </td>
                                    <td>
                                      <div className="d-flex gap-3 align-items-center">
                                        <button
                                          className="btn btn-primary btn-light-brand py-1"
                                          onClick={() => setSelectedRole(role)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#configureModal"
                                        >
                                          <UilSetting />
                                          <span>{t("manageRoles6")}</span>
                                        </button>
                                        <button
                                          className="btn btn-warning btn-light-brand py-1"
                                          onClick={() => setSelectedRole(role)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#editModal"
                                        >
                                          <UilEdit />
                                          <span>{t("manageRoles7")}</span>
                                        </button>
                                        <button
                                          className="btn btn-danger btn-light-brand py-1"
                                          onClick={() => setSelectedRole(role)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteModal"
                                        >
                                          <UilTrashAlt />
                                          <span>{t("manageRoles8")}</span>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* delete modal  */}
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex={-1}
          aria-labelledby="deleteModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {t("manageRoles9")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {" "}
                {t("manageRoles10")}{" "}
                <span className="fw-bold">{selectedRole?.name}</span> ?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {t("manageRoles11")}
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDelete}
                  disabled={isloading}
                  data-bs-dismiss="modal"
                >
                  {isloading && <span className="loader"></span>}
                  {t("manageRoles12")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*edit modal */}
        <div
          className="modal fade"
          id="editModal"
          tabIndex={-1}
          aria-labelledby="editModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {t("manageRoles13")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-4">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    className="form-control"
                    value={selectedRole?.name}
                    onChange={(e) => {
                      setSelectedRole((prevRoleData: any) => ({
                        ...prevRoleData,
                        name: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="mb-4">
                  <Select
                    options={options}
                    defaultValue={
                      selectedRole?.status
                        ? { value: "active", label: "Active" }
                        : { value: "inactive", label: "Inactive" }
                    }
                    onChange={(selectedOption) => {
                      setSelectedRole((prevRoleData: any) => ({
                        ...prevRoleData,
                        status: selectedOption?.value === "active",
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {t("manageRoles14")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={handleEdit}
                  disabled={
                    isloading ||
                    !selectedRole ||
                    !selectedRole.name ||
                    !selectedRole.status
                  }
                >
                  {isloading && <span className="loader"></span>}
                  {t("manageRoles15")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*configure modal */}
        <div
          className="modal fade"
          id="configureModal"
          tabIndex={-1}
          aria-labelledby="configureModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {t("manageRoles16")} {selectedRole?.name}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="form-check form-check-inline col-md-3 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineCheckbox1"
                    >
                      {t("manageRoles17")}
                    </label>
                  </div>
                  <div className="form-check form-check-inline col-md-3 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="option2"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineCheckbox2"
                    >
                      {t("manageRoles18")}
                    </label>
                  </div>
                  <div className="form-check form-check-inline col-md-3 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox3"
                      value="option3"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineCheckbox3"
                    >
                      {t("manageRoles19")}
                    </label>
                  </div>
                  <div className="form-check form-check-inline col-md-3 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox4"
                      value="option4"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineCheckbox4"
                    >
                      {t("manageRoles20")}
                    </label>
                  </div>
                  <div className="form-check form-check-inline col-md-3 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox5"
                      value="option5"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineCheckbox5"
                    >
                      {t("manageRoles21")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {t("manageRoles22")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={handleEdit}
                  disabled={
                    isloading ||
                    !selectedRole ||
                    !selectedRole.name ||
                    !selectedRole.status
                  }
                >
                  {isloading && <span className="loader"></span>}
                  {t("manageRoles23")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default ManageRoles;
