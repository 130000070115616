import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {};

const TermsAndConditions = (props: Props) => {
  const {t} = useTranslation();
  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center">
        <Link to={"/"}>
          <img
            src="../assets/images/dark.png"
            alt="Logo Large"
            style={{ height: "70px" }}
          />
        </Link>
      </div>
      <div className="mt-5">
        <h2 className="text-center mb-4">
          <strong>{t("termsAndConditions1")}</strong>
        </h2>
        <p className="text-justify">
          {t("termsAndConditions2")} <strong>NufiSMS</strong>! {t("termsAndConditions3")}
        </p>
        <p className="text-justify">
          <strong>NufiSMS</strong> {t("termsAndConditions4")}
        </p>
        <p className="text-justify">
          {t("termsAndConditions5")}
        </p>
        <p className="text-justify">
        {t("termsAndConditions6")}
        </p>
      </div>
      <div className="mt-5">
        <h3 className="mb-4">
          <strong>{t("termsAndConditions7")}</strong>
        </h3>
        <p className="text-justify">
         {t("termsAndConditions8")}
        </p>
        <p className="text-justify">
         {t("termsAndConditions9")}
        </p>

        <h3 className="mt-5 mb-4">
          <strong>{t("termsAndConditions10")}</strong>
        </h3>
        <p className="text-justify">
         {t("termsAndConditions11")}
        </p>
        <p className="text-justify">
          {t("termsAndConditions12")}
        </p>
      </div>
      <div className="mt-5">
        <h6 className="text-justify">
          {t("termsAndConditions13")}
        </h6>
        <p className="text-justify">
          {t("termsAndConditions14")}
        </p>

        <h3 className="mt-5 mb-4">
          <strong>{t("termsAndConditions15")}</strong>
        </h3>
        <p className="text-justify">
         {t("termsAndConditions16")}
        </p>

        <h3 className="mt-5 mb-4">
          <strong>{t("termsAndConditions17")}</strong>
        </h3>
        <p className="text-justify">
          {t("termsAndConditions18")}
        </p>
        <p className="text-justify">
          {t("termsAndConditions19")}
        </p>
      </div>
      <div className="mt-5">
        <h3 className="mb-4">
          <strong>{t("termsAndConditions20")}</strong>
        </h3>
        <p className="text-justify">
          {t("termsAndConditions21")}
        </p>

        <h3 className="mt-5 mb-4">
          <strong>{t("termsAndConditions22")}</strong>
        </h3>
        <p className="text-justify">
         {t("termsAndConditions23")}
        </p>
        <p className="text-justify">
          {t("termsAndConditions24")}
        </p>
      </div>
      <div className="mt-5">
        <h3 className="mb-4">
          <strong>{t("termsAndConditions25")}</strong>
        </h3>

        <p className="text-justify">
          {t("termsAndConditions26")}
        </p>

        <h4 className="mt-4 mb-3">
          <strong>{t("termsAndConditions27")}</strong>
        </h4>
        <p className="text-justify">
          {t("termsAndConditions28")}
        </p>
        <p className="text-justify">
         {t("termsAndConditions29")}
        </p>

        <h4 className="mt-4 mb-3">
          <strong>{t("termsAndConditions30")}</strong>
        </h4>
        <p className="text-justify">
          {t("termsAndConditions31")}
        </p>
        <p className="text-justify">
          {t("termsAndConditions32")}
        </p>

        <h4 className="mt-4 mb-3">
          <strong>{t("termsAndConditions33")}</strong>
        </h4>
        <p className="text-justify">
          {t("termsAndConditions34")}
        </p>
        <ul>
          {/* <li>
            <a href="https://youtube.com/example_sms_demo">
              Watch SMS Sending Demo
            </a>
          </li> */}
          <li>
            <a href="https://youtu.be/omLTPk72cek">
              {t("termsAndConditions35")}
            </a>
          </li>
          <li>
            <a href="https://youtu.be/sKEIw-lhED0">{t("termsAndConditions36")}</a>
          </li>
          {/* <li>
            <a href="https://youtube.com/example_schedule_sms_demo">
              Watch SMS Scheduling Demo
            </a>
          </li>
          <li>
            <a href="https://youtube.com/example_schedule_whatsapp_demo">
              Watch WhatsApp Scheduling Demo
            </a>
          </li>
          <li>
            <a href="https://youtube.com/example_campaigns_demo">
              Watch Campaigns Demo
            </a>
          </li> */}
        </ul>
      </div>
      <div className="mt-5">
        <h3 className="mb-4">
          <strong>{t("termsAndConditions37")}</strong>
        </h3>
        <p className="text-justify">
          {t("termsAndConditions38")}
        </p>
        <p className="text-justify">
          {t("termsAndConditions39")}
        </p>

        <h3 className="mt-5 mb-4">
          <strong>{t("termsAndConditions40")}</strong>
        </h3>
        <p className="text-justify">
          {t("termsAndConditions41")}
        </p>
        <p className="text-justify">
          {t("termsAndConditions42")}
        </p>
        <p className="text-justify">
          {t("termsAndConditions43")}
        </p>

        <h3 className="mt-5 mb-4">
          <strong>{t("termsAndConditions44")}</strong>
        </h3>
        <p className="text-justify">
         {t("termsAndConditions45")}
        </p>
      </div>
      <div className="mt-5 d-flex flex-column flex-md-row justify-content-between pt-2 border-top">
        <p className="text-center">
          Crafted with ❤ by{" "}
          <Link
            to={"https://digitwace.com/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            DigitWace Cameroun Sarl
          </Link>
        </p>
        <p className="text-center">
          © Copyright 2024 NufiSMS. All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
