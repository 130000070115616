import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { UilEuro } from "@iconscout/react-unicons";
import { pricingService } from "../../services/pricingService";
import toast from "react-hot-toast";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

type Props = {};

const Pricings:React.FC = (props: Props) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pricings, setPricings] = useState<any[]>([]);

  const fetchPricings = async () => {
    try {
      setLoading(true);
      const res = await pricingService.getPricings();
      if (res.status === -1) {
        toast.error("Failed to fetch pricings");
        return;
      }
      setPricings(res.data);
    } catch (error) {
      toast.error(
        "An error occurred while fetching pricings. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPricings();
  }, []);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">{t("pricings1")}</h5>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card stretch stretch-full">
                    <div className="card-body p-0">
                      {loading ? (
                        <div className="p-3">
                          <Stack spacing={1}>
                            <Skeleton variant="rectangular" height={60} />
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                            <Skeleton variant="rectangular" height={60} />
                            <Skeleton variant="rectangular" height={60} />
                          </Stack>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <table
                            className="table table-hover"
                            id="customerList"
                          >
                            <thead>
                              <tr>
                                <th className="text-nowrap">{t("pricings2")}</th>
                                <th className="text-nowrap">{t("pricings3")}</th>
                                <th className="text-nowrap">{t("pricings4")}</th>
                                <th className="text-nowrap">{t("pricings5")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pricings.length > 0 ? (
                                pricings.map((pricing, index) => (
                                  <tr key={index} className="single-item">
                                    <td>
                                      <span className="text-truncate-1-line">
                                        {pricing?.country?.name ?? "N/A"}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="hstack gap-3 text-black">
                                        <span className="text-truncate-1-line">
                                          {pricing?.country?.iso2 ?? "N/A"}(
                                          {pricing?.country?.phone_code ??
                                            "N/A"}
                                          )
                                        </span>
                                      </div>
                                    </td>
                                    <td>{pricing?.name ?? "N/A"}</td>
                                    <td className="d-flex align-items-center">
                                      {pricing?.pricing?.price_receller ??
                                        "N/A"}{" "}
                                      <UilEuro className="ht-10" />
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className="text-center my-5">
                                    {t("pricings6")}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default Pricings;
