import React from "react";
import Stack from "@mui/material/Stack";
import { LineChart } from "@mui/x-charts";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { chartsGridClasses } from "@mui/x-charts/ChartsGrid";
import { useTranslation } from "react-i18next";

type AreaChartProps = {
  xData: any[];
  yData: any[];
};

const AreaChart: React.FC<AreaChartProps> = ({ xData, yData }) => {
   const { t, i18n } = useTranslation();
  return (
    <Stack
      direction={{ xs: "column", xl: "row" }}
      spacing={2}
      alignItems="center"
      sx={{ width: "100%", display: { xs: "none", md: "block" } }}
    >
      {/* Define the gradient here */}
      <svg width="0" height="0">
        <defs>
          <linearGradient id="myGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#f17d4f" stopOpacity="0.7" />
            <stop offset="100%" stopColor="#f17d4f" stopOpacity="0.1" />
          </linearGradient>
        </defs>
      </svg>

      <LineChart
        height={400}
        series={[
          {
            id: "whatsapp",
            data: yData,
            label: t("areaChart1"),
            stack: "total",
            area: true,
            curve: "monotoneX",
            color: "#f17d4f",
          },
        ]}
        grid={{ horizontal: true }}
        xAxis={[{ scaleType: "point", data: xData }]}
        yAxis={[
          {
            min: 0,
            tickSize: 1, // Ensure whole numbers
            tickMinStep: 1,
          },
        ]}
        sx={{
          [`& .${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translateX(-10px)",
          },
          [`& .${chartsGridClasses.line}`]: {
            strokeDasharray: "5 3",
            strokeWidth: 2,
          },
          [`& .MuiAreaElement-series-whatsapp`]: {
            fill: "url('#myGradient')", // Apply the gradient here
          },
        }}
      />
    </Stack>
  );
};

export default AreaChart;
