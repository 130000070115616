import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  UilMessage,
  UilClock,
  UilTimes,
  // UilEditAlt,
  // UilEllipsisV,
  // UilSave,
  UilFileAlt,
} from "@iconscout/react-unicons";
import toast from "react-hot-toast";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { useDispatch } from "react-redux";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { Contact } from "../../models/Contacts";
import { SendMessage, SmsType } from "../../models/SMS";
import { sendMessage } from "../../redux/slices/smsSlice";
import {
  parseCSVFile,
  parseExcelFile,
  parseTextFile,
} from "../../utils/fileParsers";
import { Box } from "@mui/material";
import LinearProgressWithLabel from "../../components/LinearProgressWithLabel";
import { AppDispatch } from "../../redux/store";
import { logMessage } from "../../utils/logMessage";
import { smsService } from "../../services/smsService";
import { useTranslation } from "react-i18next";

type Props = {};

const BulkSMS = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isUploaded, setIsUploaded] = React.useState(false);
  const [selectedType, setSelectedType] = useState<SmsType | null>(null);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [smsType, setSmsType] = useState<SmsType[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [sendTime, setSendTime] = useState<Moment | null>(null);
  const [uploadedContacts, setUploadedContacts] = useState<Contact[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;
    setIsLoading(true);
    setUploadProgress(0);

    try {
      let data: Contact[] = [];

      // Simulate file reading with a progress update
      const reader = new FileReader();
      reader.onloadstart = () => setUploadProgress(0);
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setUploadProgress(progress);
        }
      };
      reader.onloadend = () => setUploadProgress(100);
      reader.readAsArrayBuffer(file);

      if (file.type === "text/csv") {
        data = await parseCSVFile(file);
      } else if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        data = await parseExcelFile(file);
      } else if (file.type === "text/plain") {
        data = await parseTextFile(file);
      }
      setIsLoading(false);
      setUploadedContacts(data);
      setIsUploaded(true);
    } catch (error) {
      logMessage("Error parsing file:");
    }

    // Clear the file input value to ensure the change event is fired for the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleDiscard = () => {
    setUploadedContacts([]);
    setIsUploaded(false);
  };

  const handleSend = useCallback(async () => {
    setSubmitting(true);

    const formattedUploadedContacts = uploadedContacts.map((contact: any) => ({
      firstname: contact.firstname || "",
      lastname: contact.lastname || "",
      email: contact.email || "",
      phone: contact.phone || "",
    }));

    let data: SendMessage = {
      sender: title,
      smsContent: message,
      contacts: [],
      contactsJson: formattedUploadedContacts,
      messageType: selectedType as SmsType,
    };

    if (sendTime) {
      data = {
        ...data,
        sendTime: sendTime.toISOString(),
      };
    }

    dispatch(sendMessage({ data }))
      .unwrap()
      .then((res: any) => {
        if (res.status === -1) {
          toast.error(res.message);
          return;
        }
        toast.success("SMS sent successfully!");
        setSendTime(null);
        setTitle("");
        setMessage("");
        setSelectedType(null);
        handleDiscard();
      })
      .catch((error: any) => {
        toast.error("Failed to send SMS", error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [uploadedContacts, title, message, sendTime, dispatch, selectedType]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value.replace(/\/../g, ""));
  };
  const handleSmsTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = smsType.find(
      (type) => type.name === event.target.value
    );
    setSelectedType(selectedType || null);
  };

  const fetchSmsTypes = async () => {
    try {
      const res = await smsService.getSmsType();
      setSmsType(res.data);
    } catch (error) {
      setSmsType([]);
    }
  };

  useEffect(() => {
    fetchSmsTypes();
  }, []);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container apps-container apps-email">
          <div className="nxl-content without-header nxl-full-content">
            <div className="main-content d-flex">
              <div
                className="content-area"
                data-scrollbar-target="#psScrollbarInit"
              >
                <div className="row p-3 pb-0">
                  <div className="mb-4 col-12 d-flex align-items-center justify-content-between">
                    <h5 className="fw-bold mb-0 me-4">
                      <span className="d-block mb-2">
                        {t("bulkSms1")}
                      </span>
                      <span className="fs-12 fw-normal text-muted text-truncate-1-line">
                        {t("bulkSms2")}
                      </span>
                    </h5>
                    <button className="btn btn-sm btn-light-brand">
                      {t("bulkSms3")}
                    </button>
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center">
                      <button
                        className="btn btn-primary me-2 d-inline-flex align-items-center justify-content-center"
                        onClick={handleButtonClick}
                      >
                        <UilFileAlt size="20" className="me-2" />
                        {isUploaded ? t("bulkSms16") : t("bulkSms15")}
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept=".csv, .xlsx, .xls, .txt"
                        style={{ display: "none" }}
                      />
                      {isUploaded && (
                        <button
                          className="btn me-2 btn-danger d-inline-flex align-items-center justify-content-center"
                          onClick={handleDiscard}
                        >
                          <UilTimes size="20" className="me-2" />
                          {t("bulkSms4")}
                        </button>
                      )}
                    </div>
                  </div>
                  {isLoading && (
                    <Box sx={{ width: "100%", mt: 2 }}>
                      <LinearProgressWithLabel value={uploadProgress} />
                    </Box>
                  )}
                  {isUploaded && (
                    <p className="text-start text-primary fw-bold">
                      {uploadedContacts.length} {t("bulkSms5")}
                    </p>
                  )}
                </div>
                <div
                  className="items-details"
                  data-scrollbar-target="#psScrollbarInit"
                >
                  <div className="items-details-footer mail-action-editor m-4 border border-top-0 rounded-3">
                    <div
                      className="p-0 border-top position-relative editor-section"
                      data-scrollbar-target="#psScrollbarInit"
                    >
                      <div className="position-relative border-bottom">
                        <div className="px-3 w-100 d-flex align-items-center">
                          <input
                            className="form-control border-0 my-1 shadow-none"
                            type="text"
                            placeholder="From"
                            maxLength={20}
                            id="title"
                            value={title}
                            onChange={handleTitleChange}
                          />
                          <span className="text-muted mt-2 text-nowrap">
                            {title.length} / 20 {t("bulkSms6")}
                          </span>
                        </div>
                      </div>
                      <div className="position-relative border-bottom d-flex align-items-center p-3 gap-3">
                        <div className="text-nowrap ">{t("bulkSms7")}: </div>
                        <select
                          className="form-control text-dark"
                          onChange={handleSmsTypeChange}
                          value={selectedType?.name || ""}
                        >
                          <option value="" disabled>
                            {t("bulkSms8")}
                          </option>
                          {smsType.map((type) => (
                            <option key={type.name} value={type.name}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="h-100 border-0 px-3" id="mailEditor">
                        <textarea
                          id="message"
                          className="w-100 border border-bottom-0 border-top ht-200"
                          placeholder={t("bulkSms17")}
                          required
                          maxLength={500}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                        <div className="text-muted mt-2">
                          {message.length} / 500 {t("bulkSms8")}
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 d-flex align-items-center justify-content-between border-top">
                      <div className="d-flex align-items-center gap-2">
                        <div className="dropdown">
                          <button
                            className="btn btn-primary dropdown-toggle"
                            data-bs-toggle="dropdown"
                            data-bs-offset="0, 0"
                            disabled={
                              submitting ||
                              title.length === 0 ||
                              message.length === 0 ||
                              selectedType === null ||
                              message.length > 500 ||
                              uploadedContacts.length === 0
                            }
                          >
                            <span
                              data-bs-toggle="tooltip"
                              data-bs-trigger="hover"
                              title="Send Message"
                            >
                              {submitting && <span className="loader"></span>}
                              {t("bulkSms9")}
                            </span>
                          </button>
                          <div className="dropdown-menu">
                            <button
                              className="dropdown-item border-0"
                              data-action-target="#mailActionMessage"
                              onClick={handleSend}
                            >
                              <UilMessage className=" me-3" />
                              <span>{t("bulkSms10")}</span>
                            </button>
                            <button
                              className="dropdown-item border-0"
                              data-bs-toggle="modal"
                              data-bs-target="#scheduleSend"
                            >
                              <UilClock className=" me-3" />
                              <span>{t("bulkSms11")}</span>
                            </button>
                            <div className="dropdown-divider"></div>
                            {/* <button className="dropdown-item successAlertMessage">
                              <UilTimes className="me-3" />
                              <span>Discard Now</span>
                            </button>
                            <button className="dropdown-item successAlertMessage">
                              <UilEditAlt className="me-3" />
                              <span>Save as Draft</span>
                            </button> */}
                          </div>
                        </div>
                        {/* <div className="dropdown d-none d-sm-block">
                          <button
                            data-bs-toggle="dropdown"
                            data-bs-offset="0, 0"
                            className="border-0 bg-transparent"
                          >
                            <span
                              className="btn btn-icon"
                              data-bs-toggle="tooltip"
                              data-bs-trigger="hover"
                              title="Pick Template"
                            >
                              <UilEllipsisV />
                            </span>
                          </button>
                          <div className="dropdown-menu wd-300">
                            <button className="dropdown-item">
                              <UilSave className="me-3" />
                              <span className="text-truncate-1-line">
                                Save as Template
                              </span>
                            </button>
                            <button className="dropdown-item">
                              <UilFileAlt className="me-3" />
                              <span>Manage Template</span>
                            </button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div
          className="modal fade"
          id="scheduleSend"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {t("bulkSms12")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="d-flex gap-2">
                  <DateTimePicker
                    label="Pick a date and time"
                    className="w-100"
                    value={sendTime}
                    onChange={(newValue) => setSendTime(newValue)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {t("bulkSms13")}
                </button>
                <button
                  type="button"
                  disabled={submitting || !sendTime}
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={handleSend}
                >
                  {submitting && <span className="loader"></span>}
                  {t("bulkSms14")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default BulkSMS;
