import React from "react";
import moment from "moment";
import { Recharge } from "../../models/Recharges";
import { UilPhone, UilComments, UilMapMarker } from "@iconscout/react-unicons";
import { User } from "../../models/User";
import { useTranslation } from "react-i18next";

type ReceiptTemplateProps = {
  recharge: Recharge;
  currentUser: User | null;
};

const ReceiptTemplate: React.FC<ReceiptTemplateProps> = ({
  recharge,
  currentUser,
}) => {
  const {t} = useTranslation();

  return (
    <div className="col-10 mx-auto">
      <div className="row">
        <div className="receipt-main">
          {/* Header */}
          <div className="row justify-content-between mb-3">
            <div className="receipt-header col">
              <div className="receipt-left">
                <img
                  className="img-responsive"
                  alt="Logo"
                  src="../assets/images/dark.png"
                  style={{ width: "150px" }}
                />
              </div>
              <div className="text-right">
                <div className="receipt-right">
                  <p className="fs-16 text-muted mb-1">
                    <UilPhone className="me-2" />
                    +1 3649-6589
                  </p>
                  <p className="fs-16 text-muted mb-1">
                    <UilComments className="me-2" />
                    support@nufisms.com
                  </p>
                  <p className="fs-16 text-muted">
                    <UilMapMarker className="me-2" />
                    {t("receiptTemplate1")}
                  </p>
                </div>
              </div>
            </div>
            <div className="receipt-header col receipt-header-mid">
              <div className="text-right">
                <div className="receipt-right d-flex flex-column align-items-end">
                  <h5>
                    {currentUser?.firstname} {currentUser?.lastname}
                  </h5>
                  <p className="fs-16 text-muted">
                    <b>{t("receiptTemplate2")}:</b> {moment().format("DD/MM/YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Receipt Details */}
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>{t("receiptTemplate3")}</th>
                  <th>{t("receiptTemplate4")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="col-md-9">{t("receiptTemplate5")}</td>
                  <td className="col-md-3">{recharge.recharge_code}</td>
                </tr>
                <tr>
                  <td className="col-md-9">{t("receiptTemplate6")}</td>
                  <td className="col-md-3">
                    {moment(recharge.date).format("DD/MM/YYYY HH:mm")}
                  </td>
                </tr>
                <tr>
                  <td className="col-md-9">{t("receiptTemplate7")}</td>
                  <td className="col-md-3">
                    {recharge.amount} {recharge.currency}
                  </td>
                </tr>
                <tr>
                  <td className="col-md-9">{t("receiptTemplate8")}</td>
                  <td className="col-md-3">
                    {recharge.payment_status.charAt(0).toUpperCase() +
                      recharge.payment_status.slice(1)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Footer */}
          <div className="row">
            <div className="receipt-header receipt-header-mid receipt-footer">
              <div className="text-left">
                <div className="receipt-right">
                  <h5 className="text-secondary text-center">
                    {t("receiptTemplate9")}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptTemplate;
