import React, { useEffect, useState } from "react";
import {
  UilMessage,
  UilClock,
  // UilTimes,
  // UilEditAlt,
  // UilEllipsisV,
  // UilSave,
  // UilFileAlt,
} from "@iconscout/react-unicons";
import Select from "react-select";
import { useFormik } from "formik";
import makeAnimated from "react-select/animated";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { AnyAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { ContactGroup } from "../../models/Contacts";
import { SendMessage, SmsType } from "../../models/SMS";
import { SendMessageToGroup } from "../../models/SMS";
import { getAllContactGroups } from "../../redux/slices/contactGroupSlice";
import { sendSmsToGroup } from "../../redux/slices/smsSlice";
import { logMessage } from "../../utils/logMessage";
import { AppDispatch } from "../../redux/store";
import { smsService } from "../../services/smsService";
import { useTranslation } from "react-i18next";

type Props = {};
interface OptionType {
  value: string;
  label: string;
}

const customStyles = {
  option: (defaultStyles: any, state: { isSelected: any }) => ({
    ...defaultStyles,
    color: state.isSelected ? "#ff488b" : "#283c50",
    backgroundColor: "transparent",
  }),

  control: (defaultStyles: any) => ({
    ...defaultStyles,
    border: "1px solid #a0a0a0",
    boxShadow: "none",
    padding: "5px",
  }),
  multiValue: (defaultStyles: any) => ({
    ...defaultStyles,
    backgroundColor: "#e5edfa",
  }),
  multiValueLabel: (defaultStyles: any) => ({
    ...defaultStyles,
    color: "#0052cc",
  }),
  multiValueRemove: (defaultStyles: any) => ({
    ...defaultStyles,
    color: "#0052cc",
    ":hover": {
      backgroundColor: "#0052cc",
      color: "white",
    },
  }),
};

const BulkToGroup = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [groups, setGroups] = useState<ContactGroup[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [selectedType, setSelectedType] = useState<SmsType | null>(null);
  const [smsType, setSmsType] = useState<SmsType[]>([]);
  const [sendTime, setSendTime] = useState<Moment | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const animatedComponents = makeAnimated();

  const transformGroupsToOptions = (groups: ContactGroup[]): OptionType[] => {
    return groups.map((group) => ({
      value: group.group_code,
      label: group.group_name,
    }));
  };

  const handleSend = (values: any) => {
    try {
      setSubmitting(true);

      let data: SendMessageToGroup = {
        sender: values.title,
        smsContent: values.message,
        messageType: selectedType as SmsType,
      };

      if (sendTime) {
        data = {
          ...data,
          sendTime: sendTime.toISOString(),
        };
      }

      dispatch(
        sendSmsToGroup({
          data,
          groupCode: values.to.value,
        }) as unknown as AnyAction
      )
        .unwrap()
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }
          toast.success("SMS sent successfully!");
          formik.resetForm();
          setSendTime(null);
          setSelectedType(null);
        })
        .catch((error: any) => {
          toast.error("Failed to send SMS", error.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      logMessage(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      to: null as OptionType | null,
      message: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().max(11).required("Required"),
      to: Yup.object().nullable().required("Required"),
      message: Yup.string()
        .max(500, "Message must be at most 500 characters")
        .required("Required"),
    }),

    onSubmit: handleSend,
  });

  const handleSmsTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = smsType.find(
      (type) => type.name === event.target.value
    );
    setSelectedType(selectedType || null);
  };
  const fetchSmsTypes = async () => {
    try {
      const res = await smsService.getSmsType();
      setSmsType(res.data);
    } catch (error) {
      setSmsType([]);
    }
  };

  useEffect(() => {
    formik.validateForm();
    fetchSmsTypes();
  }, []);
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const res = await dispatch(getAllContactGroups()).unwrap();
        setGroups(res || []);
      } catch (err) {
        console.error("Failed to fetch contact groups: ", err);
        setGroups([]);
      }
    };

    fetchGroups();
  }, [dispatch]);

  const options: OptionType[] = [...transformGroupsToOptions(groups)];

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container apps-container apps-email">
          <div className="nxl-content without-header nxl-full-content">
            <div className="main-content d-flex">
              <div
                className="content-area"
                data-scrollbar-target="#psScrollbarInit"
              >
                <div className="row p-3">
                  <div className="mb-4 col-12 d-flex align-items-center justify-content-between">
                    <h5 className="fw-bold mb-0 me-4">
                      <span className="d-block mb-2">{t("bulkToGroup1")}</span>
                    </h5>
                  </div>
                </div>
                <div
                  className="items-details"
                  data-scrollbar-target="#psScrollbarInit"
                >
                  <div className="items-details-footer mail-action-editor m-4 border border-top-0 rounded-3">
                    <div
                      className="p-0 border-top position-relative editor-section"
                      data-scrollbar-target="#psScrollbarInit"
                    >
                      <div className="position-relative border-bottom">
                        <div className="px-3 w-100 d-flex align-items-center">
                          <input
                            className="form-control border-0 my-1 shadow-none"
                            type="text"
                            placeholder={t("bulkToGroup2")}
                            maxLength={11}
                            id="title"
                            value={formik.values.title}
                            onChange={(
                              e: React.FormEvent<HTMLInputElement>
                            ) => {
                              const value = e.currentTarget.value.replace(
                                /\s/g,
                                ""
                              );
                              formik.setFieldValue("title", value);
                            }}
                            onBlur={formik.handleBlur}
                          />
                          <span className="text-muted mt-2 text-nowrap">
                            {formik.values.title.length} / 11 {t("bulkToGroup3")}
                          </span>
                        </div>
                      </div>
                      <div className="position-relative border-bottom">
                        <div className="px-2 d-flex align-items-center">
                          <div className="p-0 w-100">
                            <Select<OptionType>
                              components={animatedComponents}
                              options={options || []}
                              className="form-control border-0 text-dark"
                              placeholder={t("bulkToGroup4")}
                              styles={customStyles}
                              required
                              id="to"
                              name="to"
                              value={formik.values.to}
                              onChange={(selectedOption) => {
                                formik.setFieldValue("to", selectedOption);
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="position-relative border-bottom d-flex align-items-center p-3 gap-3">
                        <div className="text-nowrap ">{t("bulkToGroup5")}: </div>
                        <select
                          className="form-control text-dark"
                          onChange={handleSmsTypeChange}
                          value={selectedType?.name || ""}
                        >
                          <option value="" disabled>
                            {t("bulkToGroup6")}
                          </option>
                          {smsType.map((type) => (
                            <option key={type.name} value={type.name}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="h-100 border-0 px-3" id="mailEditor">
                        <textarea
                          id="message"
                          className="w-100 border border-bottom-0 border-top ht-150"
                          placeholder={t("bulkToGroup14")}
                          required
                          maxLength={500}
                          {...formik.getFieldProps("message")}
                        ></textarea>
                        <div className="text-muted mt-2">
                          {formik.values.message.length} / 500 {t("bulkToGroup7")}
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 d-flex align-items-center justify-content-between border-top">
                      <div className="d-flex align-items-center gap-2">
                        <div className="dropdown">
                          <button
                            className="btn btn-primary dropdown-toggle"
                            data-bs-toggle="dropdown"
                            data-bs-offset="0, 0"
                            disabled={
                              submitting ||
                              selectedType === null||
                              !formik.isValid
                            }
                          >
                            <span
                              data-bs-toggle="tooltip"
                              data-bs-trigger="hover"
                              title="Send Message"
                            >
                              {submitting && <span className="loader"></span>}
                              {t("bulkToGroup8")}
                            </span>
                          </button>
                          <div className="dropdown-menu">
                            <button
                              className="dropdown-item border-0"
                              data-action-target="#mailActionMessage"
                              onClick={() => formik.handleSubmit()}
                            >
                              <UilMessage className=" me-3" />
                              <span>{t("bulkToGroup9")}</span>
                            </button>
                            <button
                              className="dropdown-item border-0"
                              data-bs-toggle="modal"
                              data-bs-target="#scheduleSend"
                            >
                              <UilClock className=" me-3" />
                              <span>{t("bulkToGroup10")}</span>
                            </button>
                            {/* <div className="dropdown-divider"></div> */}
                            {/* <button className="dropdown-item successAlertMessage">
                              <UilTimes className="me-3" />
                              <span>Discard Now</span>
                            </button>
                            <button className="dropdown-item successAlertMessage">
                              <UilEditAlt className="me-3" />
                              <span>Save as Draft</span>
                            </button> */}
                          </div>
                        </div>
                        {/* <div className="dropdown d-none d-sm-block">
                          <button
                            data-bs-toggle="dropdown"
                            data-bs-offset="0, 0"
                            className="border-0 bg-transparent"
                          >
                            <span
                              className="btn btn-icon"
                              data-bs-toggle="tooltip"
                              data-bs-trigger="hover"
                              title="Pick Template"
                            >
                              <UilEllipsisV />
                            </span>
                          </button>
                          <div className="dropdown-menu wd-300">
                            <button className="dropdown-item">
                              <UilSave className="me-3" />
                              <span className="text-truncate-1-line">
                                Save as Template
                              </span>
                            </button>
                            <button className="dropdown-item">
                              <UilFileAlt className="me-3" />
                              <span>Manage Template</span>
                            </button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div
          className="modal fade"
          id="scheduleSend"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {t("bulkToGroup11")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="d-flex gap-2">
                  <DateTimePicker
                    label="Pick a date and time"
                    className="w-100"
                    value={sendTime}
                    onChange={(newValue) => setSendTime(newValue)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {t("bulkToGroup12")}
                </button>
                <button
                  type="button"
                  disabled={!formik.isValid || submitting || !sendTime}
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => formik.handleSubmit()}
                >
                  {submitting && <span className="loader"></span>}
                  {t("bulkToGroup13")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default BulkToGroup;
